import Alpine from 'alpinejs';
import Focus from '@alpinejs/focus';
import FormsAlpinePlugin from '../../vendor/filament/forms/dist/module.esm';
import NotificationsAlpinePlugin from '../../vendor/filament/notifications/dist/module.esm';
import mapboxgl from 'mapbox-gl';
import Cropper from 'cropperjs';

window.Alpine = Alpine
window.mapboxgl = mapboxgl
window.Cropper = Cropper

// Bootstrap AlpineJs...
Alpine.plugin(Focus);
Alpine.plugin(FormsAlpinePlugin);
Alpine.plugin(NotificationsAlpinePlugin);

Alpine.start();
